.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  color: var(--color-title-top-bar);
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 15px;
  min-height: 60px;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    padding: 10px 12px;
    min-height: 50px;
  }
  &:hover{
    background: var(--color-pink-hover-opacity);
  }
  &::after{
    content: '';
    width: 25px;
    height: 25px;
    border-radius: 5px;
    background: var(--color-global-project) url("/assets/img/icons/burger.svg") no-repeat 50% 50%/13px 11px;
  }

}
.ant-collapse{
  border: none;
  background: transparent;
}

.ant-collapse>.ant-collapse-item{
  border: 2px solid var(--color-border-table);
  border-radius: 8px !important;
  margin: 0 0 10px;
  overflow: hidden;
  background: var(--color-menu) !important;
}

.ant-collapse-content{
  border-top: 2px solid var(--color-border-table);
  border-radius: 0 0 8px 8px !important;
  background: transparent;
}


