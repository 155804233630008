.min-modal{
  background: var(--color-menu);
  border-radius: 16px;
  padding: 25px;
  @media screen and (max-width: 550px) {
    border-radius: 10px;
    padding: 20px;
  }
  &__title{
    color: var(--color-title-top-bar);
    font-size: 21px;
    font-weight: 700;
    line-height: 26px;
    margin: 0 0 30px;
    padding-right: 40px;
    @media screen and (max-width: 550px) {
      font-size: 19px;
      line-height: 23px;
      margin: 0 0 20px;
    }
  }
  &__text{
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    color: var(--color-gray);
    margin: 0 0 40px;
    @media screen and (max-width: 550px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  &__row{
    display: flex;
    justify-content: space-between;
    gap: 15px;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 15px;
  }
  &__close{
    position: absolute;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 23px;
    right: 25px;
    border-radius: 8px;
    z-index: 20;
    @media screen and (max-width: 550px) {
      top: 17px;
      right: 17px;
    }
  }
  &__last{
    color: var(--color-gray);
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    @media screen and (max-width: 550px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
  &__get{
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: var(--color-global-project);
    transition: color .3s ease;
    @media screen and (max-width: 550px) {
      font-size: 12px;
      line-height: 14px;
    }
    &:hover{
      color: var(--color-global-project-hover);
    }
  }
  &__btns{
    display: flex;
    gap: 15px;
    @media screen and (max-width: 550px) {
      gap: 8px;
    }
  }
  &__btn{
    width: calc(50% - 7.5px);
  }
}
