.custom-pagination{
  .ant-pagination-item,
  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link{
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: transparent;
    border: 1px solid var(--color-global-project);
    @media screen and (max-width: 1024px){
      width: 30px;
      height: 30px;
      margin-right: 4px;
      border-radius: 6px;
      font-size: 11px;
      line-height: 14px;
    }
    &:hover{
      background: var(--color-global-project);
      a{
        color: var(--color-white) !important;
      }
    }
    a{
      color: var(--color-title-top-bar) !important;
    }
  }

  .ant-pagination-prev{
    @media screen and (max-width: 1024px){
      margin: 0;
    }
  }

  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link{
    margin-top: -4px;
    @media screen and (max-width: 1024px) {
      margin-top: 1px;
    }
    &:hover{
      color: var(--color-white);
    }
  }

  .ant-pagination-next button, .ant-pagination-prev button{
    color: var(--color-title-top-bar)
  }

  .ant-pagination-disabled{
    opacity: .5;
    pointer-events: none;
  }
  .ant-pagination-item-active{
    color: var(--color-white);
    background: var(--color-global-project);
    a{
      color: var(--color-white) !important;
    }
  }

  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis{
    color: var(--color-title-top-bar)
  }

  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon{
    color: var(--color-title-top-bar)
  }

  .ant-pagination-options-size-changer.ant-select{
    width: 75px;
    padding: 0 13px;
    height: 40px;
    border-radius: 10px;
    background: transparent;
    border: 1px solid var(--color-global-project);
    @media screen and (max-width: 1024px){
      display: none;
    }
  }

}

.ant-spin-nested-loading>div>.ant-spin{
  max-height: 100%;
}

.ant-collapse-content>.ant-collapse-content-box {
  @media screen and (max-width: 1024px) {
    padding: 10px;
  }
}
