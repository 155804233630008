.table {
  position: relative;
  display: block;
  width: 100%;
  overflow-x: auto !important;
  padding: 0 0 15px;
  margin: 0 0 15px;

  &::-webkit-scrollbar {
    display: block !important;
    width: 100%;
    height: 10px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-global-project);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background: var(--bg-color);
  }

  &__checkbox {
    position: sticky !important;
    z-index: 10;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-menu);
    gap: 5px;
    min-width: 40px;
  }

  thead {
    tr {
      background: var(--color-menu) !important;
    }
  }

  tr {
    width: 100% !important;

    &.success-row {
      td {
        background: var(--color-recheck-links-success) !important;
      }

    }

    &.failed-row {
      td {
        background: var(--color-recheck-links-failure) !important;
      }

    }
  }

  th {
    cursor: pointer;
    background: var(--color-menu);
  }

  th, td {
    &.selection-cell {
      flex: unset;
    }

    a.icon-list {
      margin: 0 0.75rem
    }

    .material-icons {
      font-size: 19px;
    }

    &.site {
      width: 230px;
      min-width: 230px;
      background: var(--color-menu);
      position: sticky;
      left: 40px;
      z-index: 10;
    }

    &.contact {
      width: 160px;
      min-width: 160px;
    }

    &.anchor {
      width: 190px;
      min-width: 190px;
      white-space: normal;
    }

    &.price {
      min-width: 75px;
      width: 75px;
    }

    &.gIndex {
      justify-content: center;
      min-width: 91px;
      width: 91px;
    }

    &.found {
      justify-content: center;

      gap: 5px;
      min-width: 85px;
      width: 85px;
    }

    &.ugc {
      justify-content: center;
      min-width: 70px;
      width: 70px;
    }

    &.sponsored {
      justify-content: center;
      min-width: 118px;
      width: 118px;
    }

    &.redirected {
      justify-content: center;
      min-width: 118px;
      width: 118px;
    }

    &.message_sent {
      min-width: 138px;
      width: 138px;
    }

    &.canonical {
      justify-content: center;
      min-width: 118px;
      width: 118px;
    }

    &.nofollow {
      justify-content: center;
      min-width: 110px;
      width: 110px;
    }

    &.date_check,
    &.date_expire {
      min-width: 125px;
      width: 125px;
    }

    &.author {
      min-width: 145px;
      width: 145px;
    }

    &.link_type {
      min-width: 120px;
      width: 120px;
    }

    &.href {
      min-width: 220px;
      width: 220px;
    }

    &.boolean {
      justify-content: center;
      padding-right: 30px;
    }

    &.robots {
      justify-content: center;
      min-width: 125px;
      width: 125px;
    }

    &.x_robots {
      justify-content: center;
      min-width: 118px;
      width: 118px;
    }

    &.meta_page {
      justify-content: center;
      min-width: 115px;
      width: 115px;
    }

    &.index_quality {
      justify-content: center;
      min-width: 138px;
      width: 138px;

      span {
        &.good {
          color: var(--color-green-table-status);
        }

        &.normal {
          color: var(--color-orange-table-status);
        }

        &.bad {
          color: var(--color-red-table-status);
        }
      }

    }

    &.bad_domain {
      min-width: 138px;
      width: 138px;
    }

    &.acceptor_status {
      min-width: 180px;
      width: 180px;
    }

    &.manual-domain {
      min-width: 200px;
      width: 20%;

      a {
        color: var(--color-title-top-bar);

        &:hover {
          color: var(--color-gray);
        }
      }
    }

    &.manual-description,
    &.manual-found,
    &.manual-quantity,
    &.manual-author,
    &.manual-lost {
      min-width: 130px;
      width: 15%;
      justify-content: center;
    }

    &.manual-found {
      min-width: 134px;
    }

    &.manual-quantity {
      text-align: center;
    }

    &.manual-actions {
      display: inline-flex;
      text-align: end;
      justify-content: flex-end;
      min-width: 200px;
      width: 15%;
    }

    &.bad-domain-site {
      width: 40%;
      min-width: 330px;
    }

    &.bad-domain-href {
      width: 20%;
      min-width: 200px;
    }

    &.bad-domain-status {
      width: 10%;
      text-align: center;
      min-width: 100px;
    }

    &.bad-domain-site-author {
      width: 10%;
      text-align: center;
    }

    &.bad-domain-date,
    &.bad-domain-acceptor,
    &.bad-domain-author {
      width: 10%;
    }

    &.bad-domain-actions{
      min-width: 65px;
      display: inline-flex;
      justify-content: flex-end;
    }

    &.bad-domain-author {
      min-width: 120px;
    }

    &.bad-domain-date,
    &.bad-domain-acceptor{
      min-width: 200px;
    }


    &.helper-site {
      width: 20%;
      min-width: 250px;
    }

    &.helper-target {
      width: 20%;
      min-width: 250px;
    }

    &.helper-anchor {
      width: 20%;
      min-width: 150px;
    }

    &.helper-created {
      width: 10%;
      min-width: 150px;
    }

    &.helper-price {
      width: 10%;
      min-width: 100px;
    }

    &.helper-author {
      width: 10%;
      min-width: 100px;
    }

    &.helper-actions {
      display: flex;
      gap: 7px;
      width: 10%;
      min-width: 75px;
    }

    &.duplicates-site {
      width: 40%;
      min-width: 390px;

      a {
        word-break: break-word;
        white-space: pre-wrap;
        color: var(--color-global-project);
        transition: color .3s ease;

        &:hover {
          color: var(--color-global-project-hover);
        }
      }
    }

    &.duplicates-url {

      width: 30%;
      min-width: 150px;
    }

    &.duplicates-date {
      width: 20%;
      min-width: 150px;
    }

    &.duplicates-author {
      width: 10%;
      min-width: 100px;
      text-align: end;
      justify-content: flex-end;
    }

    &.message-type {
      width: 15%;
      min-width: 150px;
    }

    &.message-name {
      width: 25%;
      min-width: 250px;
    }

    &.message-author {
      width: 15%;
      min-width: 150px;
    }

    &.message-info {
      width: 30%;
      min-width: 300px;

      span {
        p {
          margin: 0;
        }
      }
    }

    &.message-actions {
      width: 15%;
      min-width: 100px;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }

    &.abnormal-prices-site {
      width: 35%;
      min-width: 430px;

      a {
        white-space: break-spaces;
        word-break: break-word;
        color: var(--color-global-project);
        transition: color .3s ease;

        &:hover {
          color: var(--color-global-project-hover);
        }
      }
    }

    &.abnormal-prices-price,
    &.abnormal-prices-actions,
    &.abnormal-prices-created, {
      width: 15%;
      min-width: 100px;
    }

    &.abnormal-prices-created {
      width: 25%;
      min-width: 200px;
    }

    &.abnormal-prices-author {
      width: 15%;
      min-width: 120px;
    }

    &.abnormal-prices-actions {
      text-align: end;
      width: 10%;
      min-width: 60px;
      justify-content: flex-end;
    }


  }

  td {
    &.manual-found {
      flex-direction: column;
    }
  }

  tr {
    display: flex;
    background: var(--color-menu);

    &:hover {
      background: var(--color-pink-hover-opacity);

      td {
        background: var(--color-pink-hover-opacity);
      }
    }
  }

  th {
    &.actions {
      order: 100;
      width: 100%;
      min-width: 80px;
    }
  }

  td {
    &.actions {
      width: 100%;
      min-width: 80px;
      overflow: unset;
      order: 100;
      justify-content: flex-end;

    }
  }

  tr:first-child {
    th {
      border: none;
    }

    td {
      border-top: 2px solid var(--color-border-table);
    }
  }

}

.table-not-scroll {
  margin: 0;
  display: block;

  thead {
    display: flex;
  }

  tbody {
    display: flex;
    flex-direction: column;
  }

  tr {
    display: inline-flex;
  }

  td, th {
    display: inline-flex;
  }

  td {
    &.bad-domain-site,
    &.bad-domain-href,
    &.helper-site,
    &.helper-target,
    &.helper-anchor {
      white-space: normal;
      text-align: start;
      vertical-align: middle;
      word-break: break-word;

      a {
        white-space: pre-wrap;
        color: var(--color-title-top-bar);

        &:hover {
          color: var(--color-global-project-hover);
        }
      }
    }
  }
}

.canonical-new{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  gap: 5px;
  font-size: 10px;
  line-height: 10px;
  padding: 3px 6px;
  background: var(--color-global-project);
  color: var(--color-white);
  margin: 0 0 0 5px;
}


.sort-icon {
  position: absolute;
  top: 15px;
  right: 0;
  min-width: 12px;
  height: 12px;
  margin: 0 0 0 5px;
  background: url("/assets/img/icons/sort.svg") no-repeat 0 0/contain;
  opacity: 0;
  transition: opacity .3s ease;

  &.top-sort {
    top: 12px;
    width: 18px;
    height: 18px;
    opacity: 1;
    background: url("/assets/img/icons/sort-top.svg") no-repeat 0 0/contain;
  }

  &.bottom-sort {
    top: 12px;
    width: 18px;
    height: 18px;
    opacity: 1;
    transform: rotate(180deg);
    background: url("/assets/img/icons/sort-top.svg") no-repeat 0 0/contain;
  }
}

.custom-th {
  position: relative;
  text-align: start;
  flex: unset;
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: var(--color-gray);
  border-bottom: 2px solid var(--color-border-table);

  &.sticky {
    background: var(--color-menu);
    position: sticky;
    left: 0;
    z-index: 10;
  }

  &.sort:hover .sort-icon {
    opacity: 1;
  }
}

.custom-td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: unset;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  display: inline-flex;
  padding: 5px 10px;
  align-items: center;
  vertical-align: middle;
  height: 50px;
  color: var(--color-title-top-bar);
  border-bottom: 2px solid var(--color-border-table);

  &.sticky {
    position: sticky;
    left: 0;
    z-index: 10;
  }
}


.copy-btn {
  background: transparent;
  margin: 0 10px 0 0;
  cursor: pointer;
}

.hand {
  width: 22px;
  height: 22px;
  margin: 0 0 0 8px;

  svg {
    width: 22px;
    height: 22px;
  }

}

.transfer-icon {
  width: 12px;
  height: 12px;
  transform: rotate(90deg);
  margin: 0 3px 0 0;
  svg{
    width: 12px;
    height: 12px;
  }
}


.actions {
  justify-content: flex-end;
  text-align: end;


  &-btn {
    width: 32px;
    min-width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    svg-icon, svg{
      width: 18px;
      height: 18px;
      min-width: 18px;
      @media screen and (max-width: 550px) {
        width: 15px;
        height: 15px;
        min-width: 15px;
      }
    }
    @media screen and (max-width: 550px) {
      width: 29px;
      height: 29px;
      min-width: 29px;
    }
  }

  &.sticky-actions {
    background: var(--color-menu);
    position: sticky;
    right: 0;
    z-index: 10;
  }
}


.abnormal-prices-price {
  span.price {
    display: inline-flex;
    padding: 5px 15px;
    gap: 5px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 4px;
    @media screen and (max-width: 1024px) {
      padding: 3px 10px;
      font-size: 10px;
      line-height: 10px;
      border-radius: 3px;
    }

    &.cheaper {
      color: var(--color-green-table-status);
      background: var(--color-green-bg-table-status);
    }

    &.expensive {
      color: var(--color-red-percent);
      background: var(--color-red-bg-percent);
    }

    &.normal {
      color: var(--color-purple-table-status);
      background: var(--color-purple-bg-table-status);
    }
  }
}
