:root {

  //fonts
  --font-sans: 'DMSans', sans-serif;

  //changing colors
  --color-menu: #FFFFFF;
  --bg-color: #F3F3F3;
  --bg-color-error: #FFFFFF;
  --color-side-menu-links: #32343A;
  --color-logo: #706EC7;
  --color-badges-opacity: rgba(112, 110, 199, 0.2);
  --color-title-top-bar: #111827;
  --color-title-section: #111827;
  --color-black: #000000;
  --color-bg-gray-btn: #F3F3F3;
  --color-gray-legend-chart: #5D6A83;
  --color-gray-pies: #5D6A83;
  --color-border-percent: #EEEFF2;
  --color-gray-pages-tabs: #718096;
  --color-global-project-transparent: rgba(112, 110, 199, 0.3);
  --color-global-project-transparent2: rgb(204 203 253 / 26%);
  --color-switch-bg: #FFFFFF;
  --color-switch-bg-red: #FFFFFF;
  --color-switch-range: #706EC7;
  --color-switch-range-red: #D72525;
  --color-title-form: #282D33;
  --color-placeholder-form: #282D33;
  --color-border-table: rgba(112, 110, 199, 0.20);
  --color-drops-menu-glogal: #FFFFFF;
  --color-pink-hover-opacity: rgb(239, 239, 248);
  --color-recheck-links-success: #e3f7e3;
  --color-recheck-links-failure: #ffd1d1;
  --color-purple-table-status: #4829dc;


  //static colors
  --color-gray: #718096;
  --color-yellow: #FACC15;
  --color-yellow-hover: #cfa917;
  --color-gray-transparent: #7180966e;
  --color-gray-not-this-mouht: rgba(113, 128, 150, 0.27);
  --color-pies-one: #706EC7;
  --color-pies-two: #fbba69;
  --color-pies-three: #55d859;
  --color-pies-fourth: #f152f6;
  --color-pies-five: #0081cc;
  --color-pies-six: #8e63f4;
  --color-pies-seven: #96ADFF;
  --color-green-percent: #22C55E;
  --color-red-percent: #FF4747;
  --color-red-percent-hover: #bd4242;
  --color-red-bg-percent: rgba(255, 71, 71, 0.2);
  --color-global-project: #706EC7;
  --color-global-project-hover: #8c8abe;
  --color-global-project-active: #4829dc;
  --color-green: #4CBE39;
  --color-blue: #3976BE;
  --color-blue-status: #52a4f6;
  --color-pink-status: #f152f6;
  --color-blue-bg: rgb(145 196 255 / 51%);
  --color-pink-bg: rgba(231, 145, 255, 0.45);
  --color-white: #FFFFFF;
  --color-gray-chart: #96A3BE;
  --color-gray-info: #FAFAFA;
  --color-gray-secondary: #32343A;
  --color-green-table-status: #4DC92E;
  --color-green-last-status: #6abb6a;
  --color-blue-table-status: #436CFF;
  --color-blue-bg-table-status: rgba(67, 108, 255, 0.2);
  --color-green-bg-table-status: rgba(77, 201, 46, 0.2);
  --color-orange-table-status: #E79952;
  --color-orange-bg-table-status: rgba(231, 153, 82, 0.2);
  --color-red-table-status: #D72525;
  --color-purple-bg-table-status: rgba(72, 41, 220, 0.2);
  --color-purple-active-btn: rgba(112, 110, 199, 0.4);



}

[data-theme="dark"] {
  --color-menu: #161D2B;
  --bg-color: #0F121D;
  --bg-color-error: #0F121D;
  --color-side-menu-links: #FFFFFF;
  --color-logo: #FFFFFF;
  --color-title-top-bar: #FFFFFF;
  --color-title-section: #FFFFFF;
  --color-black: #FFFFFF;
  --color-bg-gray-btn: #0F121D;
  --color-gray-legend-chart: rgba(255, 255, 255, 0.40);
  --color-gray-pies: #FFFFFF;
  --color-border-percent: #FFFFFF;
  --color-gray-pages-tabs: #FFFFFF;
  --color-switch-bg: #706EC7;
  --color-switch-bg-red: #D72525;
  --color-switch-range: #FFFFFF;
  --color-switch-range-red: #FFFFFF;
  --color-global-project-transparent: #706EC7;
  --color-title-form: #FFFFFF;
  --color-placeholder-form:rgba(255, 255, 255, 0.40);
  --color-drops-menu-glogal: #0F121D;
  --color-pink-hover-opacity: #0F121D;
  --color-recheck-links-success: #344e34;
  --color-recheck-links-failure: #552020;
  --color-purple-table-status: #b6a7ff;
}


