.select{
  display: flex;
  width: 100%;
  height: 100%;
}

.custom-select{
  background: var(--color-bg-gray-btn);
  color: var(--color-gray);
  line-height: 16px;
  font-weight: 500;
  font-size: 14px;
  padding: 8.5px 16px;
  border: none;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  min-width: 100%;
  @media screen and (max-width: 550px){
    line-height: 12px;
    font-size: 12px;
    border-radius: 6px;
    padding: 5px 15px;
  }

  .ant-select-selection-placeholder {
    @media screen and (max-width: 550px){
      font-size: 12px;
      line-height: 12px;
    }
  }
  &.border-pad{
    border-radius: 8px;
    padding: 5px 15px;
    line-height: 12px;
    font-size: 12px;
    @media screen and (max-width: 550px){
      border-radius: 6px;
      padding: 2px 15px;
    }
    .ant-select-selection-placeholder {
      font-size: 12px;
      line-height: 12px;
    }
  }
  &.border-pad-protocol{
    border-radius: 8px 0 0 8px;
    padding: 5px 15px;
    @media screen and (max-width: 550px){
      border-radius: 6px 0 0 6px;
    }
  }
  &.bg-main{
    border-radius: 12px;
    background: var(--color-menu);
  }
  &.error {
    padding: 8.5px 46px 8.5px 16px;
    background: var(--bg-color) url("/assets/img/icons/warning.svg") no-repeat 97% 12px/24px 24px;
  }

  &.disabled{
    pointer-events: none;
  }
}

.ant-select-single .ant-select-selector {
  align-items: center;
}


.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  background: transparent;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
}

.custom-select{
  &.filter{
    .ant-select-arrow, .ant-select-clear{
      background: url("/assets/img/icons/filter.svg") no-repeat 0 0/contain;
    }
  }
}

.ant-select-clear{
  display: none !important;
}

.ant-select-arrow, .ant-select-clear{
  top: 38%;
  width: 24px;
  height: 24px;
  background: url("/assets/img/icons/arrow.svg") no-repeat 0 0/contain;
  svg{
    display: none;
  }
  @media screen and (max-width: 550px){
    right: 7px;
    width: 20px;
    height: 20px;
  }
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input::placeholder{
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-gray);
}

.ant-select-dropdown{
  margin-top: 9px;
  background: var(--color-menu);
  color: var(--color-gray);
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  padding: 17px 13px;
  border: none;
  border-radius: 12px;
  min-width: calc(100% + 30px);
  left: -15px;
  @media screen and (max-width: 550px){
    padding: 9px 11px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 8px;
  }
}

.ant-select-item{
  color: var(--color-title-section);
}

.ant-select-item, .ant-select-item-empty {
  display: flex;
  min-height: 32px;
  padding: 8px 12px;
  font-size: 14px;
  margin: 0 0 5px;
  line-height: 14px;
  position: relative;
  font-weight: 500;
  border-radius: 6px;
  @media screen and (max-width: 550px){
    font-size: 12px;
    line-height: 12px;
    padding: 6px 10px;
    min-height: 29px;
  }
}

.ant-select-item-empty{
  display: block;
}

.ant-select-item-option-active{
  border-radius: 6px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-select-item-option-selected{
  border-radius: 6px;
  color: var(--color-title-section) !important;
  background: var(--color-global-project-transparent) !important;
}

.cdk-virtual-scrollable{
  min-height: 58px !important;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input{
  height: 100%;
  color: var(--color-title-section);
}

.ant-select-selection-placeholder {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: var(--color-gray);
}

//placeholder selected item
.ant-select-single .ant-select-selector .ant-select-selection-item {
  color: var(--color-title-section);
}


.ant-select-single .ant-select-selector .ant-select-selection-search {
  inset: 0 20px 0 0;
}

.ant-empty-small{
  color: var(--color-title-section);
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  box-shadow: none;
}


.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border: none;
  border-radius: 0;
}

.ant-select-multiple .ant-select-selection-placeholder {
  right: auto;
  left: 0;
}

.ant-select-multiple .ant-select-selection-item{
  background: var(--color-menu);
  color: var(--color-side-menu-links);
  height: 26px;
  border: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 1px 10px;
  border-radius: 7px;
}

.ant-select-multiple .ant-select-selection-item-remove svg{
  fill: var(--color-global-project)
}

.anticon, .anticon svg{
  fill: var(--color-black);
}

.ant-select-multiple .ant-select-selection-item-remove{
  margin-top: 0px;
}



.ant-select-multiple .ant-select-selector {
  padding: 1px 0;
}

.ant-select-item-option-content {
  display: flex;
  align-items: center;
}

.ant-modal-mask {
  backdrop-filter: blur(6px);
}
