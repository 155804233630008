* {
  padding: 0;
  margin: 0;
  border: 0;
}

*::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  background: transparent;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a {
  text-decoration: none;
  &:visited {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }
}

ul li {
  list-style: none;
  margin: 0;
}

img {
  vertical-align: top;
}

img,
picture {
  width: auto;
  height: auto;
  max-width: 100%;
}

.ant-modal-content{
  background: transparent;
}

.ant-modal-body {
  padding: 0;
  font-size: 14px;
  line-height: 15px;
}

.ant-modal-close-x{
  display: none;
}

.ant-spin-nested-loading{
  border-radius: 8px !important;
  margin: 0 !important;
}

