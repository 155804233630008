.btn-violet {
  background: transparent;
  color: var(--color-logo);
  transition: color .3s ease, background-color .3s ease;

  img,
  svg-icon{
    transition: filter .3s ease, min-width .5s ease;
  }

  &:hover {
    color: var(--color-white);
    background: var(--color-global-project);

    img,
    svg-icon{
      filter: invert(100%) brightness(200%);
    }
  }
}

.btn-violet-solid {
  background: var(--color-global-project);
  color: var(--color-white);
  transition: color .3s ease, background-color .3s ease;

  &:hover {
    color: var(--color-white);
    background: var(--color-global-project-hover);
  }
}

.btn-red-solid {
  background: var(--color-red-percent);
  color: var(--color-white);
  transition: color .3s ease, background-color .3s ease;

  &:hover {
    color: var(--color-white);
    background: var(--color-red-percent-hover);
  }
}

.btn-gray {
  border-radius: 8px;
  background: var(--color-bg-gray-btn);
  color: var(--color-logo);
  transition: color .3s ease, background-color .3s ease;

  img,
  svg-icon{
    transition: filter .3s ease;
  }

  &:hover {
    color: var(--color-white);
    background: var(--color-global-project);

    img,
    svg-icon{
      filter: grayscale(100%) invert(100%) brightness(200%);
    }
  }
}

.btn-lg{
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  padding: 11px 17px;
  @media screen and (max-width: 1024px){
    font-size: 11px;
    padding: 10px 12px;
  }

}


.btn-md {
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  padding: 8px 17px;
}

svg-icon{
  display: inline-flex;
}


.text-link {
  color: var(--color-global-project);

  &:hover {
    color: var(--color-global-project-hover);
  }
}


.text-pink {
  color: var(--color-global-project);
}





