.chart-spline {
  .apexcharts-canvas {
    position: static !important;
  }
}

.apexcharts-toolbar {
  top: 20px !important;
  right: 10px !important;
}

.apexcharts-menu-icon, .apexcharts-pan-icon, .apexcharts-reset-icon, .apexcharts-selection-icon, .apexcharts-toolbar-custom-icon, .apexcharts-zoom-icon, .apexcharts-zoomin-icon, .apexcharts-zoomout-icon {
  display: none;
  width: 50px !important;
  height: 50px !important;
  transform: scale(1) !important;
  align-items: center;
  justify-content: center;
  background: var(--color-bg-gray-btn);
  border-radius: 12px;
}

.apexcharts-menu {
  background: var(--color-bg-gray-btn) !important;
  color: var(--color-gray) !important;
}

.apexcharts-theme-light .apexcharts-menu-item:hover {
  background: var(--color-global-project) !important;
  color: var(--color-white) !important;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left, .apexcharts-legend.apx-legend-position-top.apexcharts-align-left, .apexcharts-legend.apx-legend-position-right, .apexcharts-legend.apx-legend-position-left{
  bottom: 12px !important;
}


