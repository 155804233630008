@import "variables";
@import "mixins";
@import "placeholders";
@import "fonts";
@import "~ng-zorro-antd/ng-zorro-antd.min.css";
@import "~quill/dist/quill.snow.css";
@import 'ngx-toastr/toastr';
@import "~flag-icons/css/flag-icons.min.css";
@import "reset";
@import "color-styles";
@import "basic";
@import "form";
@import "quill";
@import "charts/null-chart";
@import "ant/tooltip";
@import "ant/pagination";
@import "ant/select";
@import "dark-global/dark";
@import "modal/min-modal";
@import "ant/collapse";
@import "table";


body{
  font-family: var(--font-sans);
  background: var(--bg-color);
  color: var(--color-black);
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior: none;
}



