.form-group {
  margin: 0 0 30px;
  position: relative;
  @media screen and (max-width: 550px) {
    margin: 0 0 15px;
  }
}

.form-group-min {
  width: 100%;
  margin: 0 0 30px;
  position: relative;
  @media screen and (max-width: 1180px){
    margin: 0 0 20px;
  }
  @media screen and (max-width: 550px){
    margin: 0 0 10px;
  }
}

.form-group-photo {
  max-width: 260px;
}

.form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin: 0 0 30px;
  @media screen and (max-width: 550px) {
    margin: 0 0 20px;
  }
}

.form-row-two {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  margin: 0;
  @media screen and (max-width: 1024px) {
    gap: 10px;
  }
  @media screen and (max-width: 550px) {
    flex-direction: column;
    gap: 0;
  }
}

.form-info {
  gap: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-title-form);
  @media screen and (max-width: 550px) {
    font-size: 12px;
    line-height: 14px;
  }
}

.form-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: var(--color-title-top-bar);
  margin: 0 0 10px;
}

.form-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-gray);
  margin: 0 0 20px;

  span {
    color: var(--color-title-top-bar);
  }
}

.form-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: var(--color-gray);
  margin: 0 0 8px;
  @media screen and (max-width: 550px){
    font-size: 12px;
    line-height: 14px;
  }
}

.form-group-protocol {
  margin: 0 0 30px;
  @media screen and (max-width: 1180px){
    margin: 0 0 20px;
  }
  @media screen and (max-width: 550px){
    margin: 0 0 10px;
  }

  &__row {
    display: grid;
    flex: 1 1 auto;
    grid-template-columns: 100px 1fr;
    gap: 5px;
  }
}

.form-switch {
  display: flex;
  align-items: center;
  justify-content: center;

  input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  &.red {
    label {
      background: var(--color-switch-bg-red);
      border: 1px solid var(--color-switch-range-red);

      &::after {
        background: var(--color-switch-range-red);
      }
    }
  }

  label {
    cursor: pointer;
    width: 38px;
    height: 21px;
    background: var(--color-switch-bg);
    display: block;
    border-radius: 100px;
    position: relative;
    border: 1px solid var(--color-switch-range);
    transition: background .3s ease;
    @media screen and (max-width: 1024px) {
      width: 31px;
      height: 17px;
    }
  }

  label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 3px;
    width: 15px;
    height: 15px;
    background: var(--color-switch-range);
    border-radius: 90px;
    transition: left .3s ease;
    @media screen and (max-width: 1024px) {
      width: 11px;
      height: 11px;
    }
  }

  label.check::after {
    left: calc(100% - 18px);
    @media screen and (max-width: 1024px) {
      left: calc(100% - 14px);
    }
  }

}

.form-file {
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: center;

  &-image {
    img {
      width: 160px;
      height: 160px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.form-label-image {
  bottom: 5px;
  right: 15px;
  display: block;
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  background: var(--color-global-project) url('/assets/img/icons/input-red.svg') no-repeat center/12px 12px;
  transition: background .3s ease;

  &:hover {
    background-color: var(--color-global-project-hover);
  }
}

.form-clear-image {
  top: 5px;
  right: 15px;
  display: block;
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  background: var(--color-global-project) url('/assets/img/icons/close.svg') no-repeat center/10px 10px;
  transition: background .3s ease;

  &:hover {
    background-color: var(--color-global-project-hover);
  }
}

.form-file-input {
  display: none;
}


.form-input,
.form-textarea {
  display: inline-block;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: var(--bg-color);
  color: var(--color-black);
  font-size: 14px;
  line-height: 16px;
  @media screen and (max-width: 550px) {
    padding: 13px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 6px;
  }

  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--color-gray);
    @media screen and (max-width: 550px) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &.error {
    padding: 16px 46px 16px 16px;
    background: var(--bg-color) url("/assets/img/icons/warning.svg") no-repeat 97% 12px/24px 24px;
    @media screen and (max-width: 550px) {
      padding: 13px 36px 13px 15px;
      background: var(--bg-color) url("/assets/img/icons/warning.svg") no-repeat 97% 12px/18px 18px;
    }
  }

  &.disabled {
    pointer-events: none
  }
}

.form-textarea {
  resize: none;
  height: 100px;
}

.form-password {
  padding: 16px 36px 16px 16px;
  @media screen and (max-width: 550px) {
    padding: 15px 36px 15px 15px;
  }

  &.error {
    padding: 16px 86px 16px 16px;
    background: var(--bg-color) url("/assets/img/icons/warning.svg") no-repeat 88% 12px/24px 24px;
    @media screen and (max-width: 550px) {
      padding: 15px 46px 15px 15px;
      background: var(--bg-color) url("/assets/img/icons/warning.svg") no-repeat 88% 12px/18px 18px;
    }
  }
}

.form-checkbox {
  display: none;

  &:checked + .form-checkbox-label::after {
    opacity: 1;
  }

  &-label {
    cursor: pointer;
    position: relative;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    padding: 0 0 0 35px;
    color: var(--color-title-form);
    @media screen and (max-width: 550px){
      font-size: 14px;
      line-height: 16px;
      padding: 0 0 0 24px;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -2px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2.5px solid var(--color-global-project);
      @media screen and (max-width: 550px){
        width: 18px;
        height: 18px;
      }
    }

    &::after {
      opacity: 0;
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      left: 6px;
      top: 4px;
      border-radius: 50%;
      background: var(--color-global-project);
      transition: opacity .3s ease;
      @media screen and (max-width: 550px){
        width: 8px;
        height: 8px;
        left: 5px;
        top: 3px;
      }
    }
  }
}


.input-checkbox {
  display: none;

  &:checked + .label-checkbox::after {
    opacity: 1;
  }
}

.label-checkbox {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: var(--color-title-top-bar);
  transition: .3s ease;

  &::before {
    content: '';
    display: inline-block;
    width: 18px;
    min-width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid var(--color-gray);
    background: transparent;

  }

  &::after {
    content: '';
    background: url("/assets/img/icons/check-ok-modal.svg") no-repeat 0 0/cover;
    position: absolute;
    width: 18px;
    min-width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .3s ease;
  }
}

.ant-spin-nested-loading {
  margin: 0 0 30px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before, .ant-spin-dot-item {
  background: var(--color-global-project-active);
}

.form-submit-btn {
  display: inline-block;
  width: 100%;
  font-weight: 700;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  padding: 16px 15px;
  text-align: center;
  color: var(--color-white);
  background: var(--color-global-project);
  transition: background .3s ease;
  @media screen and (max-width: 550px) {
    font-size: 12px;
    line-height: 13px;
    padding: 14px 15px;
  }

  &:hover {
    background: var(--color-global-project-hover);
  }

  &:disabled {
    pointer-events: none;
    opacity: .7;
  }
}

button {
  &:disabled {
    pointer-events: none;
    opacity: .7;
  }
}

.form-link {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-title-form);
  @media screen and (max-width: 550px) {
    font-size: 12px;
    line-height: 14px;
  }

  &:hover {
    color: var(--color-title-form);
  }

  &:hover::before {
    opacity: 0;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    background: var(--color-title-form);
    opacity: 1;
    transition: opacity .3s ease;
  }
}


.show-password {
  cursor: pointer;
  position: absolute;
  top: 13px;
  right: 16px;
  height: 22px;
  width: 22px;
  background: url("/assets/img/icons/hide-pass.svg") no-repeat 0 0/contain;
  transition: background .3s ease;
  @media screen and (max-width: 550px) {
    height: 18px;
    width: 18px;
  }

  &.show {
    background: url("/assets/img/icons/show-pass.svg") no-repeat 0 0/contain;
  }
}

.validation {
  padding: 5px;
  display: flex;
  flex-direction: column;

  small {
    font-size: 12px;
    line-height: 15px;
    color: var(--color-red-percent);
  }
}

.form-search {
  &-input {
    width: 100%;
    border-radius: 8px;
    display: inline-block;
    padding: 14px 16px 14px 52px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: var(--color-gray);
    background: var(--color-bg-gray-btn) url("/assets/img/icons/search.svg") no-repeat 15px 50%/20px 20px;

    &::placeholder {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: var(--color-gray);
      @media screen and (max-width: 550px) {
        font-size: 12px;
        line-height: 13px;
      }
    }

    @media screen and (max-width: 550px) {
      font-size: 12px;
      line-height: 13px;
      border-radius: 6px;
      background-position: 10px 50%;
      background-size: 15px 15px;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--bg-color) inset !important;
  -webkit-text-fill-color: var(--color-black) !important;
  color: var(--color-black) !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ant-modal-wrap {
  padding: 30px 0;
}



