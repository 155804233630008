.main{
  @media screen and (max-width: 1024px) {
    margin-left: 49px;
  }
  @media screen and (max-width: 550px) {
    margin-left: 40px;
  }
  &__wrapper{
    padding: 25px 20px;
    max-width: 100%;
    @media screen and (max-width: 1024px) {
      padding: 15px 10px;
    }
  }
}

.check-link-btn{
  svg-icon{
    &.load{
      animation: 3s linear infinite rotateLoading;
    }
  }
}

@keyframes rotateLoading {
  0% {
    transform: rotate(0deg);
  }
  100%  {
    transform: rotate(360deg);
  }
}
