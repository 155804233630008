body[data-theme='dark']{


  .btn-violet,
  .btn-gray,
  .copy-text-btn,
  .projects__details-btn,
  .info-modal-site,
  .info-payment__item-btn,
  .projects-more__item-archive,
  .side-menu__logo,
  .top-bar__logo{
    svg-icon,
    img{
      filter: grayscale(100%) invert(100%) brightness(200%);
    }
  }

  .custom-td{
    &.site,
    &.href,
    &.manual-domain,
    &.my-sites-domain,
    &.expenses-links-site{
      svg-icon,
      img{
        filter: grayscale(100%) invert(100%) brightness(200%);
      }
    }
  }


  .info-icon-domain{
    filter: grayscale(100%) invert(100%) brightness(200%);
  }

  .messages__image{
    img.bots-image{
      filter: grayscale(100%) invert(100%) brightness(200%);
    }
  }

  .statistic__image {
    background: url("/assets/img/icons/bg-icon-dark.svg") no-repeat 0 0/contain;
    img,
    svg-icon{
      filter: invert(100%) brightness(200%);
    }
  }

  .side-menu__link {
    svg-icon {
      filter: invert(100%) brightness(200%);
    }
  }

  .load-gindex{
    filter: invert(100%) brightness(200%);
  }

  .side-bar-user__link-image {
    img,
    svg-icon{
      filter: invert(100%) brightness(200%);
    }
  }

  .side-menu__btn-toggle {
    svg-icon {
      filter: grayscale(100%) invert(100%) brightness(300%);
    }
  }

  .top-bar__tooltip-link {
    img,
    svg-icon{
      filter: invert(100%) brightness(200%);
    }
  }

  .show-password {
    background: url("/assets/img/icons/hide-pass-dark-theme.svg") no-repeat 0 0/contain;

    &.show {
      background: url("/assets/img/icons/show-pass-dark-theme.svg") no-repeat 0 0/contain;
    }
  }

  .ant-select-dropdown{
    box-shadow: 0 3px 6px -4px #0000001f,0 6px 16px #00000014,0 9px 28px 8px #000000fd;
  }
}

.dark-theme-img{
  filter: none !important;
  img,
  svg-icon{
    filter: none !important;
  }
}
