.ant-tooltip-inner{
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  min-height: 28px;
  background: var(--color-drops-menu-glogal);
  color: var(--color-title-top-bar);
}
.ant-tooltip-arrow-content:before{
  background: var(--color-drops-menu-glogal);
}

.ant-select-dropdown{
  max-height: 300px;
}

.ant-popover-inner{
  border-radius: 8px;
}

.ant-popover-inner-content{
  padding: 5px 10px;
  background: var(--color-drops-menu-glogal);
  color: var(--color-title-top-bar);
}

.ant-popover-arrow-content:before {
  background: -10px -10px no-repeat  var(--color-drops-menu-glogal);
}

.ant-popover-inner-content{
  border-radius: 8px;
}

.ant-checkbox-checked:after{
  border-color: var(--color-pies-one);
}

.ant-checkbox-checked .ant-checkbox-inner{
  background: var(--color-pies-one);
  border-color: var(--color-pies-one);
}

.ant-checkbox-input:focus+.ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner{
  border-color: var(--color-pies-one);
}

.ant-checkbox+span{
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: .2px;
  color: var(--color-gray);
}

.ant-checkbox-inner {
  background-color: var(--bg-color);
  border: 1px solid var(--color-pies-one);
}
